import { useState, useContext, useEffect } from 'react';
import { Button, CardMedia, CircularProgress, ClickAwayListener, Container, Grid, MenuItem,
  Tab, Tabs, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { DataGrid, DataGridProps, GridColDef,getGridDateOperators,gridClasses } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { useAppDispatch } from 'store/hooks';
import { getUnassignedConversations, getAssignedConversations, 
  getVMAStatus, getConversationCount, getVMANames, assignConversationToOthers, 
  getConversationGroupDetails,
  joinConversation} from 'store/slices/secureMessageSlice';
import COLORS from 'theme/colors';
import { AuthContext } from 'utils/AuthContext';
import online from 'assets/images/online1.svg';
import offline from 'assets/images/offline.svg';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'route/RouteEnums';
import chat from 'assets/images/chat.svg';
import { CustomToolbar } from 'utils/functions';

const ConversationList = () => {

  const [conversations, setConversations] = useState<any[] | null>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [conversationCount, setConversationCount] = useState<any>('');
  const [vmaStatus, setVMAStatus] = useState<any>();
  const [vamNames, setVMANames] = useState<any>();
  const [selectedVMA, setSelectedVMA] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const [open, setOpen] = useState<any>();
  const [groupDetails, setGroupDetails] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState(0);
  
  const dispatch = useAppDispatch();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  
  const handleTooltipClose = () => {
    setOpen(null);
  };

  const StyledTooltip = styled(({ className, color, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltipArrow}`]: {
      backgroundColor: '#fff!important',
      boxShadow: theme.shadows[1]
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #fff',
        color:'#fff',              
      },      
    },
    [`& .${tooltipClasses.tooltip}`]:{
      border: '1px solid #fff',
      padding:'12px',
      borderRadius:'8px'
    }
  }));

  const getUnassignedConversationsList = async () => { 
    setLoading(true);   
    await dispatch(getUnassignedConversations({}))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) { 
          setConversations(response?.data);
          setLoading(false);
        }
      });
  };

  const getAssignedConversationsList = async () => {
    setLoading(true);    
    await dispatch(getAssignedConversations({}))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setConversations(response?.data);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if(user?.roleType !== 'Global Manager'){
      navigate(ROUTE.ADMIN_DASHBOARD);
    }
    getAssignedConversationsList();
    loadConversationCount();
    dispatch(getVMAStatus({}))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){
          const data = {
            online: 
            // eslint-disable-next-line array-callback-return
            response.data.map((x:any) => {
              if(x.status === 'available'){
                return x.count;
              }
            }).filter((ele:any) => ele),
            // eslint-disable-next-line array-callback-return
            offline : response.data.map((x:any) => {
              if(x.status === 'offline'){
                return x.count;
              }
            }).filter((ele:any) => ele),
          };
          setVMAStatus(data);
        }
      });

    dispatch(getVMANames({}))
      .unwrap()
      .then((res: any) => {
        if(res.status === 200){
          setVMANames(res?.data);
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadConversationCount=()=>{
    dispatch(getConversationCount({}))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){
          const data = {
            assigned: 
          // eslint-disable-next-line array-callback-return
          response.data.map((x:any) => {
            if(x.status === 'in-progress'){
              return x.count;
            }
          }).filter((ele:any) => ele),
            // eslint-disable-next-line array-callback-return
            unassigned : response.data.map((x:any) => {
              if(x.status === 'open'){
                return x.count;
              }
            }).filter((ele:any) => ele),
          };
          setConversationCount(data);
        }
      }); 
  };
  const handleVMAChange=(e:any, params:any)=>{
    setSelectedRow(params?.row?.groupId);
    setSelectedVMA(e.target.value);
  };

  const handleAssignConversation=(params:any)=>{
    const data = {
      groupId: params.row.groupId,
      id:selectedVMA
    };
    dispatch(assignConversationToOthers(data))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){
          toast('Conversation Assigned',{
            type: 'success',
            icon: true,
          });
          getUnassignedConversationsList(); 
          loadConversationCount(); 
        } else{
          toast('Something went wrong',{
            type: 'error',
            icon: true,
          });
        }
      });
  };

  const columns: GridColDef[] = [ 
    {
      field:'action',
      headerName:'Action',
      filterable:false,
      sortable:false,  
      renderCell: (params:any) => {
        return (
          <>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <StyledTooltip 
                arrow             
                onClose={handleTooltipClose}
                onMouseLeave={handleTooltipClose}
                open={open === params.row.patientId}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <span style={{height:'150px'}}>                    
                    {groupDetails === null ? 
                      <CircularProgress/> :
                      <>
                        {groupDetails?.length !==0 ?
                          <>
                            <Grid container 
                              spacing={1}
                              direction='row'
                              alignItems='center'
                              justifyContent='space-between'                          
                            >
                              <Grid item xs={11} style={{cursor:'default'}}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}>
                    Select Type to Start
                                </Typography> 
                              </Grid>
                              <Grid item xs={1} >
                                <Typography  sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}
                                  onClick={(e: any) => { e.stopPropagation(); handleTooltipClose(); }}> X </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>                      
                              {groupDetails?.map((g:any) => {
                                return(
                                  <>
                                    <Grid item xs={9}>
                                      <Typography 
                                        sx={{ color: '#3467FF', fontSize: '14px', 
                                          fontWeight: 500, mb: '16px', cursor: 'pointer'
                                        }} 
                                        onClick={(e:any) => { handleJoinConversation(e,g); }}
                                      >
                                        {g.groupName}
                                      </Typography>                         
                                    </Grid>
                                    <Grid item xs={3} onClick={(e:any) => { handleJoinConversation(e,g); }}>
                                      {g.activeConversation ? 
                                        <CardMedia src={online} component='img' 
                                          sx={{ width: '10px', height: '10px', mt: '4px', ml:'20px' }}/> 
                                        : ''}
                                    </Grid>
                                  </>
                                );
                              })
                              }
                            </Grid>
                          </> : 
                          <Grid container 
                            spacing={1}
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'                          
                          >
                            <Grid item xs={11} style={{cursor:'default'}}>
                              <Typography sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}>
                                No groups
                              </Typography> 
                            </Grid>
                            <Grid item xs={1} >
                              <Typography  sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}
                                onClick={(e: any) => { e.stopPropagation(); handleTooltipClose(); }}> X </Typography>
                            </Grid>
                            <Typography sx={{ fontSize: '14px', color:'#1C1F24', mb:'16px' }}>
                               There are no groups available.
                            </Typography>
                          </Grid>
                        }
                      </>
                    }
                  </span>          
                } 
                placement='right-end'
                sx={{paddingLeft:'50px'}}
                PopperProps={{
                  disablePortal: true,
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [20, -60],
                      },
                    },
                  ],
                }}
              >
                <CardMedia src={chat} component='img' sx={{ width: '24px', height: 'auto', cursor:'pointer' }}
                  onClick={(e:any) => {handleTooltipOpen(e, params?.row?.patientId);}}
                />
              </StyledTooltip>
            </ClickAwayListener>
          </>
        );
      }
    },
    {
      field: 'guardianName',
      headerName: 'Guardian Name',
      flex: 1.5,
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      flex: 1.5,
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      flex: 1,
      renderCell:(params:any) => {
        return(
          <>
            {selectedTab === 0 ?
              params?.row?.assignedTo  :
              <>
                <TextField 
                  id='assignTo'
                  variant='outlined' 
                  fullWidth 
                  select
                  label='Select VMA'
                  onChange={(e:any) => handleVMAChange(e, params)}
                  value={selectedRow === params?.row?.groupId ? selectedVMA : ''}
                  sx={{minWidth:'120px'}}
                > 
                  {vamNames?.length && vamNames.map((v:any) =>{
                    return(                      
                      <MenuItem key={v.firstName+v.lastName} value={v.userId}>
                        {v.firstName+' '+v.lastName}</MenuItem>                      
                    );
                  })}                
                </TextField> 
                <Button variant='text' 
                  disabled = {selectedRow === params?.row?.groupId ? false : true}
                  onClick={() => handleAssignConversation(params)}>Assign</Button>
                     
              </>
            }  
          </>
        );
      }
    },
    {
      field: 'type',
      headerName: 'Category',
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 1,
      type:'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params?.row?.startDate).format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params?.row?.startDate).format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.startDate ? dayjs(params?.row?.startDate).format('MM/DD/YYYY') : '-'}
          </>
        );
      }
    },
  ];

  const handleTabChange = (event: any, newValue: any) => {
    setConversations([]);
    setSelectedTab(newValue);
    if(newValue === 0){
      getAssignedConversationsList();
    } else if(newValue === 1){
      getUnassignedConversationsList();
    }
  };

  const handleTooltipOpen=(e:any, id:number)=>{
    e.stopPropagation();
    setOpen(id);
    dispatch(getConversationGroupDetails(id))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){
          setGroupDetails(response.data);
        }
      });
  };

  const handleJoinConversation = (e: any, g:any) => {
    e.stopPropagation();
    dispatch(joinConversation(g.groupId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          navigate(`${ROUTE.ADMIN_MESSAGES}`);
        }
      });
  };

  const handleOnStateChange=(e:any)=>{
    setFilteredRows(e.rowsMeta.positions.length);
  };
  return (
    <Container maxWidth='lg'>
      <Grid item xs={12}>
        <Typography
          variant='h5'
          position={'relative'}
          width={'fit-content'}
          sx={{ paddingTop: 6, textTransform: 'capitalize' }}
        >
          Conversation List
        </Typography>
      </Grid>
  
      <Grid container mb={'24px'} p={'6px'} spacing={2}  xs={12} justifyContent='space-between'>
        <Grid item xs={3.5} justifyContent='flex-start'>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              background: '#F9FAFB', borderRadius: '23px', marginTop: '10px',
              '.MuiTab-root.Mui-selected': {
                background: '#9F84FF',
                color: '#fff',
                borderRadius: '23px',
                fontWeight: 500
              },
              '.MuiTabs-flexContainer' :{
                justifyContent: 'left',
                padding:'6px',
                borderRadius:'24px',
                border:'1px solid #F2F4F7',
                background:'#F9FAFB'
              },
              
            }}
            TabIndicatorProps={{
              style: {
                background: 'transparent',
              }
            }}
            centered
          > 
            <Tab
              label={`Open Assigned (${conversationCount && conversationCount?.assigned.length ? 
                conversationCount?.assigned[0] : 0})`}
            />
            <Tab
              label={`Unassigned (${conversationCount && conversationCount?.unassigned.length ? 
                conversationCount?.unassigned[0] : 0})`}
            />
          </Tabs>
        </Grid>
        <Grid item xs={3} alignSelf={'flex-end'} justifySelf={'flex-end'}
          sx={{background:'#fff', 
            border:'1px solid #F2F4F7', 
            borderRadius:'8px',
            justifyContent:'center',
            padding:'6px 16px'
          }}
        >
          <Typography variant='body1' sx={{fontWeight:700, paddingBottom:'6px'}}>Agent Status </Typography>
          <Grid container>
            <Grid item xs={6} sx={{display:'flex'}}>
              <CardMedia component='img' src={online} alt='image'  
                sx={{
                  width: '16px',
                  height: '16px',
                  marginRight: '10px'
                }}/> 
              <Typography sx={{fontSize:'14px', fontWeight:400}}>Online </Typography>
              <Typography sx={{fontSize:'14px', fontWeight:600, pl:'5px'}}> ({vmaStatus?.online.length ?
                vmaStatus?.online[0] : 0})</Typography>
            </Grid>
            <Grid item xs={6} sx={{display:'flex'}}>
              <CardMedia component='img' src={offline} alt='image' 
                sx={{
                  width: '16px',
                  height: '16px',
                  marginRight: '10px'
                }}/> 
              <Typography sx={{fontSize:'14px', fontWeight:400}}>Offline </Typography>  
              <Typography sx={{fontSize:'14px', fontWeight:600, pl:'5px'}}> ({vmaStatus?.offline.length ? 
                vmaStatus?.offline[0] : 0})</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: COLORS.White }}>    
        <DataGrid
          autoHeight
          loading={loading}
          rows={conversations ? conversations : []}
          columns={columns}
          getRowId={(row) => row.groupId}
          slots={{
            toolbar: CustomToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
            sorting:{
              sortModel:[{field:'id', sort:'desc'}]
            }
          }}
          onStateChange={handleOnStateChange}
          rowHeight={filteredRows !== 0 && filteredRows < 3 ? 150 : 50}
          columnVisibilityModel={{
            id:false,
            action: selectedTab === 1 ? false : true
          }}
          pageSizeOptions={[10, 25, 50]}
          rowSpacingType='margin'
          getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          style={{ cursor: 'default' }}
          sx={{
            border: 'none',
            backgroundColor: COLORS.LightYellow,
            fontSize: 14,
            fontWeight: 400,
            '.MuiDataGrid-toolbarContainer': {
              background: '#FFFBF1',
            },
            '.MuiDataGrid-columnHeaders': {
              marginBottom: 2,
              boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
            },
            '.MuiDataGrid-columnHeadersInner': {
              background: COLORS.White,
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600,
            },
            [`& .${gridClasses.row}.even`]: {
              backgroundColor: COLORS.White,
              '&:hover, &.Mui-hovered': {
                backgroundColor: COLORS.LightBlue,  
              },
            },
            [`& .${gridClasses.row}.odd`]: {
              backgroundColor: COLORS.LightGray,
              '&:hover, &.Mui-hovered': {
                backgroundColor: COLORS.LightBlue,
              },
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none !important',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 400,
              fontSize: '16px',
              color: '#000000',
              lineHeight: '24px'
            },
          }}
          localeText={{ noRowsLabel: 'No conversations available' }}
          
        /> 
      </Grid>
    </Container>
  );
};
export default ConversationList;