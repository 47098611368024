import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, } from 'react-router-dom';
import OnboardingRouter from 'route/OnboardingRouter';
import { AuthContextProvider } from 'utils/AuthContext';
import ScrollToTop from 'components/ScrollToTop';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';
import { Dialog, DialogTitle, Grid, DialogContent, Button, Typography } from '@mui/material';
import PinInput from 'react-pin-input';
import { checkPin } from 'store/slices/userSlice';
import { useAppDispatch } from 'store/hooks';
import Offline from 'offline';

function App() {
  let ele: PinInput | null;
  const [showDialog, setShowDialog] = useState(false);
  const [changeDialogText, setChangeDialogText] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showOffline, setShowOffline] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    getIdleTime,
  } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  });

  useEffect(() => {

    if (navigator.onLine === false) {
      setShowOffline(true);
    }

    if (window.location.pathname === '/') {
      localStorage.removeItem('onIdle');
    }
    const onIdle = localStorage.getItem('onIdle');
    window.addEventListener('storage', function (e) {
      if (e.key === 'onIdle' && e.newValue === null && window.location.pathname !== '/') {
        window.location.href = '/signout';
      }
    });

    if (onIdle === '1') {
      setShowDialog(true);
    }
    else if (onIdle === '2') {
      setShowDialog(true);
      setChangeDialogText(true);
    }
  }, [showDialog]);

  useEffect(() => {
    window.addEventListener('visibilitychange', () => {
      const session = sessionStorage.getItem('user');
      const user = session && JSON.parse(session);
      if (document.visibilityState === 'visible' &&
        window.location.pathname !== '/' && window.location.pathname !== '/signup'
        && window.location.pathname !== '/migration/signup' &&
        window.location.pathname !== '/transfer/signup' && !user?.isAdmin) {      
        localStorage.setItem('onIdle', '2');
        setShowDialog(true);
        setChangeDialogText(true);
      }
    });
    const onIdle = localStorage.getItem('onIdle');
    if (onIdle === '1') {
      setInterval(() => {
        const result = getIdleTime();
        if (result >= 86400000) {
          setShowDialog(false);
          localStorage.setItem('onIdle', '0');
          window.location.href = '/signout';
        }
      }, 60000);
    }
  }, [getIdleTime, showDialog]);

  const onIdle = () => {
    const session = sessionStorage.getItem('user');
    const user = session && JSON.parse(session);
    if (window.location.pathname !== '/' && window.location.pathname !== '/signup' &&
      window.location.pathname !== '/migration/signup' && 
      window.location.pathname !== '/transfer/signup' && !user?.isAdmin) {
      localStorage.setItem('onIdle', '1');
      setShowDialog(true);
    }
  };

  const handleOnComplete = (value: string) => {
    const data = {
      'pin': value
    };

    dispatch(checkPin(data))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          if (response?.data?.status === 'VALID') {
            setShowDialog(false);
            localStorage.setItem('onIdle', '0');
            window.location.reload();
          } else if (response?.data?.status === 'INVALID') {
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 2000);
            if (ele) { ele.clear(); }
          }
        }
      });
  };

  const handleLogout = () => {
    setShowDialog(false);
    localStorage.removeItem('onIdle');
    window.location.href = '/signout';
  };

  return (
    <AuthContextProvider>
      {showOffline ?
        <Offline />
        :
        <>
          <Router>
            <IdleTimerProvider
              timeout={1000 * 60 * 10}
              onIdle={onIdle}
            >
              <ScrollToTop />
              {OnboardingRouter()}
            </IdleTimerProvider>
          </Router>
          <Dialog aria-labelledby="simple-dialog-title"
            open={showDialog} sx={{ zIndex: 9999 }}>
            <DialogTitle id="simple-dialog-title" sx={{ padding: '16px 24px 0' }}>
              {!changeDialogText ? 'Session Timeout' : ''}</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                {!changeDialogText ?
                  <Typography>Your session was paused due to inactivity.</Typography> : ''}
                <Typography>Please re-enter your 6-digit PIN to continue.</Typography>

                <Grid item xs={3} mt={'20px'}>
                  <PinInput
                    length={6}
                    focus
                    secret
                    secretDelay={500}
                    type='numeric'
                    inputMode='numeric'
                    onComplete={handleOnComplete}
                    inputStyle={{
                      border: '1px solid #FFC44D',
                      borderRadius: '8px',
                      margin: '10px 15px'
                    }}
                    ref={(n) => ele = n}
                  />
                </Grid>
                {showError ? <Typography color={'red'}> Invalid PIN. Please try again.  </Typography> : ''}
                <Typography variant='h3' mt='12px'>OR</Typography>
                <Grid container justifyContent="center">
                  <Button variant='text' onClick={handleLogout} sx={{ textAlign: 'right' }}> LOG IN AGAIN </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      }
    </AuthContextProvider>
  );
}

export default App;
