/* eslint-disable max-len */

import { useState, useEffect, useContext } from 'react';
import {
  Grid, Container, Typography, styled, Card, CardProps,
  CardContent, Box, Button,
  TextField,
  FormControlLabel,
  FormControlLabelProps,
  Alert,
  Checkbox,
  InputLabel,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { useAppDispatch } from 'store/hooks';
import {actionRequestForAdmin, getContactDetails , getRequestDetails, sendRequest } from 'store/slices/accountManagementSlice';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { toast } from 'react-toastify';
import { AuthContext } from 'utils/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';


const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
  border: '1px solid #EFF4FF',
  borderRadius: 12,
  background: '#FFF',
  margin: '12px 16px',
  position: 'relative',
  zIndex: 99,
  '& .MuiTypography-root': {
    marginBottom: '8px',
    position: 'relative',
  },
  width: '100%',
}));


const EmailPhoneNumber = (props:any) => {
  const [selectedRequest, setSelectedRequest] = useState<string>('CHANGE_EMAIL');
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [currentPhone, setCurrentPhone]= useState<string>('');
  const [email, setEmail] = useState<any>();
  const [phoneNumber,setPhoneNumber] = useState<any>();
  const [name, setName] = useState<any>();
  const [emptyEmail, setEmptyEmail] = useState<boolean>(false);
  const [emptyName, setEmptyName] = useState<boolean>(false);
  const [emptyPhone, setEmptyPhone] = useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [invalidPhone, setInvalidPhone] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [reasonForRejection, setReasonForRejection] = useState<any>('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showAfterActionModal, setShowAfterActionModal] = useState<boolean>(false);
  const [changeSuccessMessage,setChangeSuccessMessage] = useState<boolean>(false);
  const [showRejectionModal, setShowRejectionModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const {user} = useContext(AuthContext);
  const { state } = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const data={
      patientId:user?.patientId,
      requestType:selectedRequest
    };
    dispatch(getContactDetails(data))
      .unwrap()
      .then((response:any) => {
        if(response?.status === 200){        
          setCurrentEmail(response?.data?.currentEmail);
          setCurrentPhone(response?.data?.currentPhone);
        }
      });

    if (state?.ticketId) {
      dispatch(getRequestDetails(state?.ticketId))
        .unwrap()
        .then((response: any) => {
          if (response?.status === 200) {
            const details = JSON.parse(response?.data?.requestData);                        
            setCurrentEmail(details?.currentEmail);
            setCurrentPhone(details?.currentPhone);
            setPhoneNumber(details?.newPhone);
            setEmail(details?.newEmail);
            setName(details?.name);
            setSelectedRequest(details?.ticketType);
            setReasonForRejection(details?.reasonForRejection);

          }
        });
    }
      
  },[user?.patientId, dispatch, selectedRequest, state]);

  
  const handleRelationCheckbox=(e:any)=>{
    if(e.target.checked){
      setDisableSubmitButton(false);
      setIsChecked(true);
    } else {
      setDisableSubmitButton(true);
      setIsChecked(false);
    }
  };

  const handleSelectedTab =(val:any)=>{
    setSelectedRequest(val);
    setEmail(undefined);
    setPhoneNumber(undefined);
    setEmptyName(false);
    setEmptyEmail(false);
    setEmptyPhone(false);
    setInvalidEmail(false);
    setInvalidPhone(false);
    setIsChecked(false);
    setDisableSubmitButton(true);
  };

  const onSubmitForm=()=>{
    
    if((selectedRequest ==='CHANGE_EMAIL' || selectedRequest === 'CHANGE_BOTH') && email === undefined){
      setEmptyEmail(true);     
    }
    else if((selectedRequest === 'CHANGE_PHONE' || selectedRequest === 'CHANGE_BOTH') && phoneNumber=== undefined){
      setEmptyPhone(true);
    
    }

    if(name === undefined || name === ''){
      setEmptyName(true);
    }

    const data={
      requestData: JSON.stringify({
        ticketType: selectedRequest ,
        newEmail : email,
        currentEmail: currentEmail,
        currentPhone: currentPhone,
        newPhone: phoneNumber,
        name : name,
        patientId: user?.patientId,
        userId: user?.userId
      }),
      patientId: user?.patientId,
      userId: user?.userId,
      requestType: selectedRequest,
    };

    dispatch(sendRequest(data))
      .unwrap()
      .then((response:any) => {
        if(response.status === 200){
          if(response?.data?.includes('Old')){
            setChangeSuccessMessage(true);
          }
          setShowSuccessModal(true);
        }
      });
  };

  const validateEmail = (email:any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  const handleEmailChange=(e:any)=>{
    setEmail(e.target.value.toLowerCase());
    if(!validateEmail(e.target.value.toLowerCase())){
      setInvalidEmail(true);
    } else {
      setEmptyEmail(false);
      setInvalidEmail(false);
    }
  };

  const handlePhoneNumberChange=(val:any)=>{
    if(val.length !== 11){
      setInvalidPhone(true);
    }else{
      setEmptyPhone(false);
      setInvalidPhone(false);
    }
    setPhoneNumber(val);
  };

  const handleAction=(action:string)=>{    
    const data={
      ticketId:state?.ticketId,
      action,
      reasonForRejection
    };
    dispatch(actionRequestForAdmin(data))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setShowAfterActionModal(true);
        }
      });
  };

  const handleReject=()=>{
    setShowRejectionModal(true);
  };

  return (
    <Page backDir={props?.from === 'admin' ? ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST : ROUTE.ACCOUNT_MANAGEMENT}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Container component='section'>
            <Grid container>
              <Grid item xs={6} sm={7}>            
                <Typography
                  variant='h5'
                  position={'relative'}
                  width={'fit-content'}
                  sx={{ paddingTop: 6, textTransform: 'capitalize' }}
                >
                Update Email / Phone Number
                </Typography>                
              </Grid>
              {state?.ticketId ?
                <Grid item  alignItems={'flex-start'}>
                  <Typography 
                    sx={{background: state?.status === 'Rejected' || state?.status === 'Update Failed' || state?.status === 'Transfer Failed' ? '#FFFBFA' : state?.status === 'Approved' || state?.status === 'Update Successful' || state?.status === 'Transfer Successful' ? '#F6FEF9' : '#F5F8FF', 
                      color: state?.status === 'Rejected' || state?.status === 'Update Failed' || state?.status === 'Transfer Failed' ? '#B42318' : state?.status === 'Approved' || state?.status === 'Update Successful' || state?.status === 'Transfer Successful'? '#027A48' : '#1570EF', 
                      border: state?.status === 'Rejected' || state?.status === 'Update Failed' || state?.status === 'Transfer Failed' ? '1px solid #B42318' : state?.status === 'Approved' || state?.status === 'Update Successful' || state?.status === 'Transfer Successful' ? '1px solid #027A48' : '1px solid #1570EF', 
                      borderRadius: '12px', padding: '2px 10px 0px 10px', marginTop: 8,
                    }}> 
                    {state?.status}
                  </Typography>
                </Grid> : null }              
            </Grid>
            <Grid container spacing={3} pt='20px'>
              <Grid item xs={4} sm={2} textAlign={'center'}>
                <Button 
                  sx={{ background: selectedRequest === 'CHANGE_EMAIL' ? '#9F84FF' : '#EBE9FE', 
                    color:selectedRequest === 'CHANGE_EMAIL' ? '#FFF' : '#000', 
                    borderRadius: '12px', 
                    padding: '10px 0', 
                    cursor: 'pointer',
                    minWidth: {xs:'120px', sm:'120px'},
                    minHeight:{xs:'62px', sm:'44px'},
                    fontSize:'14px',
                    fontWeight:300,
                    textTransform:'none',
                    fontFamily:'Graphik-Regular, sans-seri',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: '#9F84FF',
                      color:'#fff',
                      fontFamily:'Graphik-Regular, sans-seri'
                    },
                    '&.Mui-disabled':{
                      background: state?.ticketId && selectedRequest === 'CHANGE_EMAIL' ? '#667085' :'#F2F4F7',
                      color: state?.ticketId && selectedRequest === 'CHANGE_EMAIL' ? '#D0D5DD' :'#98A2B3'                      
                    }                    
                  }} 
                  disableElevation
                  disableRipple
                  disabled = {state?.ticketId }
                  onClick={() => handleSelectedTab('CHANGE_EMAIL')}> Change Email </Button>
              </Grid>
              <Grid item xs={4} sm={2.2} textAlign={'center'}>
                <Button 
                  sx={{background: selectedRequest === 'CHANGE_PHONE' ? '#9F84FF' :'#EBE9FE', 
                    color:selectedRequest === 'CHANGE_PHONE' ? '#FFF' : '#000', 
                    borderRadius: '12px', 
                    padding:'10px 5px', 
                    cursor:'pointer',
                    minWidth: {xs:'120px', sm:'180px'},
                    minHeight:{xs:'62px', sm:'44px'},
                    fontSize:'14px',
                    fontWeight:300,
                    textTransform:'none',
                    fontFamily:'Graphik-Regular, sans-seri',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: '#9F84FF',
                      color:'#fff',
                      fontFamily:'Graphik-Regular, sans-seri'
                    },
                    '&.Mui-disabled':{
                      background: state?.ticketId && selectedRequest === 'CHANGE_PHONE' ? '#667085' :'#F2F4F7',
                      color: state?.ticketId && selectedRequest === 'CHANGE_PHONE' ? '#D0D5DD' :'#98A2B3'                      
                    }                    
                  }}
                  disableElevation
                  disableRipple
                  disabled = {state?.ticketId }
                  onClick={() => handleSelectedTab('CHANGE_PHONE')}> Change Phone Number </Button>
              </Grid>
              <Grid item xs={4} sm={2.9} textAlign={'center'}>
                <Button sx={{background: selectedRequest === 'CHANGE_BOTH' ? '#9F84FF' :'#EBE9FE', 
                  color:selectedRequest === 'CHANGE_BOTH' ? '#FFF' : '#000', 
                  borderRadius: '12px',
                  padding:'10px 0', 
                  cursor:'pointer',
                  minWidth: {xs:'120px', sm:'120px'},
                  minHeight:{xs:'62px', sm:'44px'},
                  fontSize:'14px',
                  fontWeight:300,
                  textTransform:'none',
                  fontFamily:'Graphik-Regular, sans-seri',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#9F84FF',
                    color:'#fff',
                    fontFamily:'Graphik-Regular, sans-seri'
                  },
                  '&.Mui-disabled':{
                    background: state?.ticketId && selectedRequest === 'CHANGE_BOTH' ? '#667085' :'#F2F4F7',
                    color: state?.ticketId && selectedRequest === 'CHANGE_BOTH' ? '#D0D5DD' :'#98A2B3'                      
                  }                    
                }}
                disableElevation
                disableRipple
                disabled={state?.ticketId}
                onClick={() => handleSelectedTab('CHANGE_BOTH')}> Change Both </Button>
              </Grid>
            </Grid>

            <Grid container marginTop={4}>
              {reasonForRejection !== undefined && reasonForRejection !=='' ?
                <StyleCard sx={{padding: {xs:'0px', sm:'0px 36px'}}}>
                  <CardContent>
                    <Typography variant='h2'>This Request is Rejected!</Typography>
                    <InputLabel>Reason for Rejection</InputLabel>
                    <Typography sx={{border:'1px solid #D0D5DD', borderRadius:'8px', padding:'10px 14px', background:'#EEF0F6'}}>{reasonForRejection}</Typography>
                  </CardContent>
                </StyleCard> : null}

              <StyleCard sx={{padding: {xs:'0px', sm:'0px 36px'}}}>  
                <CardContent>
                  <Alert severity='info' sx={{color:'#1D2939', fontSize:'14px', fontWeight:400}}>
                    { selectedRequest === 'CHANGE_EMAIL' ?
                      <Typography> Update Primary Guardian&apos;s email here. For Secondary Guardian&apos;s email, go to Patient Profile &gt; Guardian Information.</Typography> 
                      : selectedRequest === 'CHANGE_PHONE' ?
                        <Typography>Update Primary Guardian&apos;s phone number here. For Secondary Guardian&apos;s phone number, go to Patient Profile &gt; Guardian Information.</Typography>
                        : <Typography>Update Primary Guardian&apos;s email & phone number here. For Secondary Guardian&apos;s email & phone number, go to Patient Profile &gt; Guardian Information.</Typography>                 
                    } </Alert>
                 
                  <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                    { selectedRequest === 'CHANGE_EMAIL' ?  <Typography variant='h2'>Primary Guardian Email Details</Typography> :
                      selectedRequest === 'CHANGE_PHONE' ?  <Typography variant='h2'>Primary Guardian Phone Number Details</Typography> :
                        <Typography variant='h2'>Primary Guardian Email & Phone Number Details</Typography> }
                    { selectedRequest === 'CHANGE_EMAIL'  ?
                      <Grid container columnSpacing={3}>
                        <Grid item xs={12} sm={6} mt='24px'> 
                          <InputLabel> Current Email Address</InputLabel>
                          <TextField
                            fullWidth  
                            value={currentEmail}
                            disabled/>
                        </Grid>
                        <Grid item xs={12} sm={6} mt='24px'> 
                          <InputLabel> New Email Address*</InputLabel>
                          <TextField
                            fullWidth                            
                            id='email'
                            value={email}
                            onChange={(e:any) => handleEmailChange(e)}
                            disabled={state?.ticketId ? true :false}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '&.MuiInputBase-root fieldset': {
                                  borderColor: emptyEmail || invalidEmail ? '#d32f2f' : '#D0D5DD',
                                },
                              },
                            }}
                          />
                          {emptyEmail || invalidEmail? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Valid Email </Typography> : ''}
                        </Grid>
                      </Grid> : 
                      selectedRequest === 'CHANGE_PHONE' ?
                        <Grid container columnSpacing={3} >
                          <Grid item xs={12} sm={6} mt='24px'> 
                            <InputLabel> Current Phone Number</InputLabel>
                            <TextField
                              fullWidth  
                              value={currentPhone}
                              disabled/>
                          </Grid>
                          <Grid item xs={12} sm={6}  mt='24px'> 
                            <InputLabel> New Phone Number*</InputLabel>
                            <ReactPhoneInput
                              component={TextField}
                              label=''
                              onlyCountries={['us']}
                              country={'us'}
                              masks={{ us: '(...) ...-....' }}
                              autoFormat={true}
                              disableDropdown={true}
                              defaultErrorMessage={'Please enter a phone number'}
                              countryCodeEditable={false}
                              onChange={(e:any) => handlePhoneNumberChange(e)}
                              disabled={state?.ticketId ? true :false}
                              value={phoneNumber}
                            />
                            {emptyPhone || invalidPhone ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Valid Phone Number. </Typography> : ''}
                        
                          </Grid>
                        </Grid> : 
                        <Grid container columnSpacing={3} >
                          <Grid item xs={12} sm={6} mt='24px'> 
                            <InputLabel> Current Email Address</InputLabel>
                            <TextField
                              fullWidth  
                              value={currentEmail}
                              disabled/>
                          </Grid>
                          <Grid item xs={12} sm={6} mt='24px'> 
                            <InputLabel> New Email Address*</InputLabel>
                            <TextField
                              fullWidth
                              id='email'
                              value={email}
                              onChange={(e:any) => handleEmailChange(e)}
                              disabled={state?.ticketId ? true :false}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '&.MuiInputBase-root fieldset': {
                                    borderColor: emptyEmail || invalidEmail ? '#d32f2f' : '#D0D5DD',
                                  },
                                },
                              }}
                            />
                            {emptyEmail || invalidEmail? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Valid Email </Typography> : ''}
                          </Grid>
                          <Grid item xs={12} sm={6} mt='30px'> 
                            <InputLabel> Current Phone Number</InputLabel>
                            <TextField
                              fullWidth  
                              value={currentPhone}
                              disabled/>
                          </Grid>
                          <Grid item xs={12} sm={6} mt='30px'> 
                            <InputLabel> New Phone Number*</InputLabel>
                            <ReactPhoneInput
                              component={TextField}
                              label=''
                              onlyCountries={['us']}
                              country={'us'}
                              masks={{ us: '(...) ...-....' }}
                              autoFormat={true}
                              disableDropdown={true}
                              defaultErrorMessage={'Please enter a phone number'}
                              countryCodeEditable={false}
                              onChange={(e:any) => handlePhoneNumberChange(e)}
                              value={phoneNumber}
                              disabled={state?.ticketId ? true :false}
                            />
                            {emptyPhone || invalidPhone ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Valid Phone Number. </Typography> : ''}                        
                          </Grid>
                        </Grid>
                    }
                  </Box>

                  <Box m='16px 0' pb='24px'>
                    <Typography variant='h2'>Submitted by</Typography>
                    <Grid container columnSpacing={3} mt='24px' mb='24px' >
                      <Grid item xs={12} sm={6}> 
                        <InputLabel> Full Name*</InputLabel>
                        <TextField
                          id='name'
                          fullWidth  
                          placeholder='Enter Full Name'
                          onChange={(e:any) => {setName(e.target.value); setEmptyName(false);}}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.MuiInputBase-root fieldset': {
                                borderColor: emptyName ? '#d32f2f' : '#D0D5DD',
                              },
                            },
                          }}
                          value={name}
                          disabled={state?.ticketId ? true :false}
                        />
                        {emptyName ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Name </Typography> : ''}
                      </Grid>
                    </Grid>

                    <InputLabel> Relationship to Patient</InputLabel>                
                    <FormControlLabel 
                      sx={{
                        '.MuiFormControlLabel-label':{
                          marginTop :'10px',
                          fontWeight:700
                        }
                      }}
                      control={<Checkbox onChange={(e:any)=>handleRelationCheckbox(e)} checked={isChecked || state?.ticketId}/>}
                      label="Primary Guardian" />      
                  </Box>
                </CardContent> 
                 
              </StyleCard>
            </Grid>

            <Grid justifySelf={'center'} sx={{marginTop:'36px'}}>
              {state?.ticketId && props?.from !== 'admin' ?
                <Button variant='outlined' sx={{ minWidth: { xs: '140px', sm: '200px' } }} onClick={() => navigate(ROUTE.ACCOUNT_MANAGEMENT)}>  Back  </Button>
                : <>
                  
                  {props?.from === 'admin' ?

                    <>                    
                      {state?.status === 'Rejected' || state?.status === 'Approved' || state?.status === 'Update Successful' || state?.status === 'Transfer Successful' ?
                        <Button variant='outlined' onClick={() => navigate(ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST)}>Back</Button> 
                        :                         
                        <>
                          <Button type='submit' variant='outlined' onClick={() => handleReject()} sx={{ minWidth: { xs: '140px', sm: '200px' }, mr: '20px' }}> Reject </Button>
                          <Button type='submit' onClick={() => handleAction('Approved')} sx={{ minWidth: { xs: '140px', sm: '200px' } }}> 
                            { state?.status === 'Update Failed'? 'Retry' : 'Approve'} </Button>
                        </>
                      }                    
                    </>
                    :
                    <>
                      <Button variant='outlined' sx={{ marginRight: '20px', minWidth: { xs: '140px', sm: '200px' } }}
                        onClick={() => navigate(ROUTE.ACCOUNT_MANAGEMENT)}>  Cancel  </Button>
                      <Button disabled={disableSubmitButton} type='submit' onClick={onSubmitForm} sx={{ minWidth: { xs: '140px', sm: '200px' } }}> Submit </Button>
                    </>
                  }
                </>}

            </Grid>
            <Dialog  aria-labelledby="success-dialog"  open={showSuccessModal} >
              <DialogTitle id="simple-dialog-title" sx={{padding:'30px 0 0 0'}}>
                <Typography variant='h2'> {changeSuccessMessage ? 'Last request is Under Review':'Request Submitted Successfully'} </Typography></DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={'36px'}
                > 
                  <Typography variant='h3'>{changeSuccessMessage ? 'Please wait until we complete your last request.' : 'We will review and get back to you soon.'} </Typography>           
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => navigate(ROUTE.ACCOUNT_MANAGEMENT)} sx={{mb:'24px'}}> OKAY</Button>
              </DialogActions>
            </Dialog>
            <Dialog  aria-labelledby="success-dialog"  open={showAfterActionModal} >
              <DialogTitle id="simple-dialog-title" sx={{padding:'30px 0 0 0'}}>
                <Typography variant='h2'> Request Approved Successfully </Typography></DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={'36px'}
                > 
                  <Typography variant='h3'> Submit a ticket to the engineering team to process this request. </Typography>           
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => {setShowAfterActionModal(false); navigate(ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST);}} sx={{mb:'24px'}}> OKAY</Button>
              </DialogActions>
            </Dialog>

            <Dialog  aria-labelledby="success-dialog"  open={showRejectionModal} >
              <DialogTitle id="simple-dialog-title" sx={{padding:'30px 0 0 0'}}>
                <Typography variant='h2'> Reject Request </Typography></DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="left"
                  justifyContent="left" 
                  pt='20px'                                   
                > 
                  <InputLabel>Reason for Rejection*</InputLabel>
                  <TextField
                    multiline
                    placeholder='Enter reason here'
                    onChange={(e:any) => setReasonForRejection(e?.target?.value)}
                    rows={4}
                    variant='filled'                    
                  />
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='outlined' sx={{mb:'24px'}} onClick={() => {setShowRejectionModal(false); setReasonForRejection('');}}>Cancel</Button>
                <Button onClick={()=>handleAction('Rejected')} sx={{mb:'24px'}} disabled={reasonForRejection === undefined || reasonForRejection === ''}> Reject</Button>
              </DialogActions>
            </Dialog>

          </Container>
        </Grid >
      </Grid >
    </Page>
  );
};

export default EmailPhoneNumber;
 